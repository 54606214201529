:root {
  --mobile-ui-slide: 150px
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.content-row {
  width: 1200px;
  max-width: calc(100% - 100px);
}
#intro {
  display: flex;
  justify-content: center;
  background: rgb(42,46,74);
  height: 1000px;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}
#bottom {
  background-color: #E6E8F9;
  padding-bottom: 120px;
}
#navbar {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  transition: box-shadow 0.3s;
  -webkit-transition: box-shadow 0.3s;
  -moz-transition: box-shadow 0.3s;
  -ms-transition: box-shadow 0.3s;
  -o-transition: box-shadow 0.3s;
  background-color: rgb(42,46,74);
  z-index: 3;
}
#navbar-content-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#navbar-content-row > img {
  height: 70%;
  cursor: pointer;
}
#navbar-buttons {
  display: flex;
  align-items: center;
}
#login-button {
  margin-left: 60px;
}
#navbar-buttons > a {
  margin-left: 60px;
  color: white;
  font-family: rubik;
  font-weight: 400;
  font-size: 20px;
  cursor: pointer;
  user-select: none;
}
#intro-content {
  display: flex;
  align-items: center;
  position: relative;
}
#intro-left {
  width: 50%;
  z-index: 2;
}
#intro-left-big {
  font-size: 69px;
  font-family: rubik;
  font-weight: 500;
  margin-bottom: 0px;
  margin-top: 0;
}
#intro-left-big > .important {
  font-weight: 700;
  background: -webkit-linear-gradient(0deg, #00FF8F, #00A1FF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#intro-left-subtitle {
  margin-top: 10px;
  font-family: rubik;
  font-size: 22px;
  opacity: 0.8;
}
#intro-buttons {
  display: flex;
  align-items: center;
}
#intro-button-start {
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background: linear-gradient(90deg, #00e5d2, #0085d2);
  outline: none;
  border: none;
  cursor: pointer;
  transition: background-size 0.2s;
  -webkit-transition: background-size 0.2s;
  -moz-transition: background-size 0.2s;
  -ms-transition: background-size 0.2s;
  -o-transition: background-size 0.2s;
  background-size: 100% 100%;
  margin-right: 40px;
}
#intro-button-start:hover {
  background-size: 200% 100%;
}

#intro-button-about-us {
  cursor: pointer;
  font-family: rubik;
  font-size: 24px;
  color: white;
  text-decoration: none;
}

#intro-button-start > span {
  font-family: rubik;
  font-size: 26px;
  font-weight: 500;
  margin-left: 10px;
  color: white;
}
#intro-right {
  display: flex;
  justify-content: right;
  flex-grow: 1;
}
#static {
  max-height: 100%;
  overflow: auto;
  height: 100%;
  margin-top: 0px;
}

.overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 4;
}
.popup-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  background-color: #F2F4FE;
  width: 800px;
  height: 500px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  z-index: 5;
  max-width: calc(100% - 35px);
}
.popup-header {
  font-family: rubik;
  font-size: 26px;
  width: 100%;
  height: 48px;
  background-color: #2A2E4A;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: white;
  display: flex;
  padding-left: 14px;
  box-sizing: border-box;
  align-items: center;
}
#map-choice-container {
  margin-inline: 20px;
  margin-top: 20px;
  flex-grow: 1;
  margin-bottom: 20px;
  min-height: 0;
  min-width: 0;
  display: flex;
  flex-direction: column;
}
#map-choice-container > div {
  height: 298px;
}
#maps-search-bar {
  padding-inline: 20px;
  box-sizing: border-box;
  width: 100%;
  margin-top: 20px; 
}
#popup-choose-map {
  display: flex;
  flex-direction: column;
}
#map-choice-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
#map-choice-scrollbars-container {
  margin-bottom: 20px;
}
#choose-map-confirm-button {
  width: fit-content;
  margin-inline: auto 0;
}




#menu-icon {
  display: none;
}


#how-does-it-work-content > .title {
  text-align: center;
  margin-top: 0px;
  font-family: rubik;
  color: rgb(36, 36, 49);
  font-size: 43px;
  font-weight: 500;
}
#how-does-it-work-content > .badges {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
#how-does-it-work-content > .badges > .badge {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#how-does-it-work-content > .badges > .badge > .image {
  width: 90%;
  margin-bottom: 10px;
}
#how-does-it-work-content > .badges > .badge > .title {
  font-weight: 500;
  width: 100%;
  text-align: center;
  font-family: rubik;
  color: black;
  margin-bottom: 0px;
  font-size: 22px;
}
#how-does-it-work-content > .badges > .badge > .description {
  font-family: rubik;
  color: #3E424F;
  width: 100%;
  text-align: center;
  margin-top: 5px;
  font-size: 20px;
}
#how-does-it-work-content > .badges > .badge > .description > .special-1 {
  color: #12CA79;
}
#how-does-it-work-content > .badges > .badge > .description > .special-2 {
  color: #00A1FF;
}
#properties-panel, #zoom-panel, #right-bar {
  color: white;
}
@media only screen and (max-width: 1300px) {
  #intro-left-big {
    font-size: 60px;
  }
  #intro-left-subtitle {
    font-size: 18px;
  }
  #intro-button-start {
    width: 200px;
    height: 45px;
  }
  #intro-button-start > span {
    font-size: 20px;
  }
  #intro-button-about-us {
    font-size: 20px;
  }
  #navbar {
    height: 80px;
  }
  #static {
    margin-top: 80px;
  }
  #how-does-it-work-content > .badges > .badge > .title {
    font-size: 19px;
  }
  #how-does-it-work-content > .badges > .badge > .description {
    font-size: 16px;
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 1150px) {
  .content-row {
    width: 1100px;
  }
  #intro-content {
    flex-direction: column;
    justify-content: center;
  }
  #intro-left {
    width: 900px;
    max-width: 100%;
    text-align: center;
  }
  #intro-buttons {
    justify-content: center;
  }
  #intro-button-about-us {
    display: none;
  }
  #intro-button-start {
    margin-right: 0px;
  }
  #intro-right {
    opacity: 0.1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
  }
  #navbar {
    height: 80px;
  }
  #static {
    margin-top: 80px;
  }
}
@media only screen and (max-width: 680px) {
  #navbar-buttons {
    display: none;
  }
  #intro-left-big {
    font-size: 50px;
  }
  #menu-icon {
    display: flex;
  }
}

@media only screen and (max-width: 1600px) and (min-width: 1300px) {
  .content-row {
    width: 1300px;
  }
  #intro-left-big {
    font-size: 70px;
  }
  #intro-left-subtitle {
    font-size: 20px;
  }
  #intro-button-start {
    width: 220px;
    height: 50px;
  }
  #intro-button-start > span {
    font-size: 22px;
  }
  #navbar {
    height: 80px;
  }
  #static {
    margin-top: 80px;
  }
  #intro-button-about-us {
    font-size: 22px;
  }
  #how-does-it-work-content > .badges > .badge > .title {
    font-size: 21px;
  }
  #how-does-it-work-content > .badges > .badge > .description {
    font-size: 18px;
  }
}
@media only screen and (max-height: 1230px) {
  #intro {
    height: 800px
  }
}
@media only screen and (max-height: 1000px) {
  #intro {
    height: 650px;
  }
}
@media only screen and (max-width: 1420px) and (min-width: 1300px) {
  .content-row {
    width: 1200px;
  }
  #intro-left-big {
    font-size: 60px;
  }
  #intro-left-subtitle {
    font-size: 18px;
  }
  #intro-button-start {
    width: 200px;
    height: 45px;
  }
  #intro-button-start > span {
    font-size: 20px;
  }
  #intro-button-about-us {
    font-size: 20px;
  }
  #navbar {
    height: 80px;
  }
  #static {
    margin-top: 80px;
  }
  #how-does-it-work-content > .badges > .badge > .title {
    font-size: 21px;
  }
  #how-does-it-work-content > .badges > .badge > .description {
    font-size: 17px;
  }
}

#bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#how-does-it-work-content {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-bottom: 100px;
}

.territory-fill-picker {
  color: black
}

#toolbar {
  box-shadow: #00000059 -7px 12px 60px;
  background-color: #465077;
  display: flex;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%) scale(0.8);
  transform-origin: 50% 100%;
  height: 110px;
  border-radius: 10px;
  justify-content: space-between;
}
.toolbar-button {
  height: 100%;
  width: 130px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.toolbar-button > .special {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -ms-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
}
.toolbar-button:hover > .special {
  pointer-events: all;
  opacity: 1 !important;
}

.toolbar-button:hover > .top {
  background-color: #3f4869;
}
.toolbar-button > .top.selected {
  background-color: #3e445b;
}
.toolbar-button > .top {
  height: 65px;
  width: 100px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.toolbar-button > .top > img {
  max-width: 100%;
  max-height: 100%;
}
.toolbar-button > .bottom {
  font-family: rubik;
  font-weight: 500;
  color: white;
  opacity: 0.8;
  user-select: none;
}

#properties-container, #right-bar-container {
  width: 350px;
}
#zoom-panel-positioner {
  right: 350px;
  top: 20px;
  position: absolute;
}

@media only screen and (max-width: 1430px) {
  #properties-container, #right-bar-container {
    width: 300px;
  }
  #zoom-panel-positioner {
    right: 300px;
  }
  #toolbar {
    transform: translateX(-50%) scale(0.7);
  }
}
@media only screen and (max-width: 1240px) {
  #toolbar {
    transform: translateX(-50%) scale(0.6);
  }
}

#map-div .annotation {
  transition: opacity 0.2s;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -ms-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
}
#map-div.cursor .annotation {
  opacity: 0.2;
  pointer-events: none;
}
#map-div.annotations .annotation {
  cursor: pointer;
}
#map-div.annotations .annotation {
  cursor: pointer;
}
#map-div.annotations .territory {
  opacity: 0.1;
}
.data-visualizer {
  z-index: 10;
}
.special.download > .panel > .button {
  width: 100%;
  height: 40px;
  color: white;
  font-family: rubik;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  transition: background-color 0.1s;
  -webkit-transition: background-color 0.1s;
  -moz-transition: background-color 0.1s;
  -ms-transition: background-color 0.1s;
  -o-transition: background-color 0.1s;
}
.special.download > .panel > .button:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px
}
.special.download > .panel > .button:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.special.download > .panel > .button:hover {
  background-color: #3f4869;
}
.special.pen > .panel > .button.color-container {
  padding: 10px;
  box-sizing: border-box;
  height: auto;
}
.special.pen > .panel > .button.color-container > div {
  width: 100%;
  height: 100px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}
.special {
  z-index: 2;
}

#map-div.marker {
  cursor: pointer;
}
#right-bar {
  padding-right: 8px;
  overflow: auto;
  padding: 8px;
  box-sizing: border-box;
}

#right-bar::-webkit-scrollbar {
  width: 10px;
  border: 10px transparent solid;
}
#right-bar::-webkit-scrollbar * {
  background: transparent; 
}
#right-bar::-webkit-scrollbar-thumb {
  background:rgba(0,0,0,0.1) !important;
  width: 3px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
}
#properties-panel::-webkit-scrollbar {
  width: 10px;
  border: 10px transparent solid;
}
#properties-panel::-webkit-scrollbar * {
  background: transparent; 
}
#properties-panel::-webkit-scrollbar-thumb {
  background:rgba(0,0,0,0.1) !important;
  width: 3px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
}

#right-bar > .territory-div {
  width: 100%;
  height: 25px;
  color: rgb(255, 255, 255, 0.8);
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.1s;
  -webkit-transition: background-color 0.1s;
  -moz-transition: background-color 0.1s;
  -ms-transition: background-color 0.1s;
  -o-transition: background-color 0.1s;
  margin-top: 3px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  background-color: rgb(0, 0, 0, 0.03);
  display: flex;
  align-items: center;
  font-family: rubik;
  padding-inline: 8px;
  box-sizing: border-box;
  justify-content: space-between;
}
#right-bar > .territory-div > span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}
#right-bar > .territory-div > .buttons {
  display: flex;
}
#right-bar .territory-div.selected {
  background-color: rgb(0, 0, 0, 0.1);
}
#right-bar > .territory-div:hover {
  background-color: rgb(0, 0, 0, 0.1);
}
#bottom > .feature {
  width: 100%;
  display: flex;
  justify-content: center;
}
#bottom > .feature.second {
  background-color: #EEF0FF;
}
#bottom > .feature > .content-row {
  height: 500px;
  margin-top: 100px;
  margin-bottom: 100px;
  display: flex;
}
#bottom > .feature > .content-row > .left {
  width: 50%;
  padding-right: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
#bottom > .feature > .content-row > .right {
  width: 50%;
  padding-left: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.feature-description {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: 0 auto;
}
.feature-description > .title {
  font-family: rubik;
  color: rgb(36, 36, 49);
  font-weight: 500;
  font-size: 50px;
  margin: 0px;
  margin-bottom: 10px;
}
.feature-description > .description {
  font-family: rubik;
  color: #3E424F;
  font-weight: 400;
  margin: 0px;
  font-size: 25px;
}


@media only screen and (max-width: 1070px) {
  #toolbar {
    width: calc(100% - 40px);
    transform: scale(1);
    left: 20px;
    height: 70px;
    bottom: calc(var(--mobile-ui-slide) + 20px)
  }
  #toolbar > .toolbar-button {
    justify-content: space-between;
    padding: 6px;
    width: 100px;
    min-width: 70px;
  }
  #toolbar > .toolbar-button > .top {
    height: 37px;
    width: 100%;
    padding: 5px;
  }
  #toolbar > .toolbar-button > .bottom {
    font-size: 14px;
  }
  #properties-container {
    width: calc(100%);
    left: 0px;
    height: 600px !important;
    top: calc(100% - var(--mobile-ui-slide) - 20px) !important;
  }
  #right-bar-container {
    width: calc(100%);
    left: 0px;
    height: 600px !important;
    top: calc(100% - var(--mobile-ui-slide) + 560px) !important;
  }
  #map-svg {
    max-width: 100vw;
  }
  #zoom-panel-positioner {
    top: 20px;
    right: 20px;
  }
  .toolbar-button > .special {
    width: 100% !important;
  }
  .toolbar-button > .special > .panel > .button.size-container {
    font-size: 12px;
  }
}
#eyedropper-label {
  font-size: 25px;
}
@media only screen and (max-width: 570px) {
  #toolbar > .toolbar-button > .bottom {
    font-size: 10px;
  }
  #map-choice-grid {
    grid-template-columns: 1fr;
  }
  #eyedropper-label {
    font-size: 20px !important;
  }
}
@media only screen and (max-width: 700px) {
  #how-does-it-work-content > .badges {
    flex-direction: column;
  }
  #how-does-it-work-content > .badges > .badge {
    width: 100%;
    margin-bottom: 30px;
  }
  #how-does-it-work-content > .badges > .badge > .image {
    width: 210px;
    margin-bottom: 10px;
  }
  #how-does-it-work-content > .badges > .badge > .title {
    font-size: 24px;
    margin-top: 2px;
  }
  #how-does-it-work-content > .badges > .badge > .description {
    font-size: 20px;
    width: 270px;
  }
  .feature > .content-row {
    flex-direction: column !important;
  }
  .feature > .content-row > .left {
    width: 100% !important;
  }
  .feature > .content-row > .right {
    align-items: center !important;
    text-align: center !important;
    width: 100% !important;
    margin: 0px !important;
  }
  .feature > .content-row > .right > .title {
    font-size: 32px;
  }
  .feature > .content-row > .right > .description {
    font-size: 17px;
  }
  #image-fill-picker-popup {
    grid-template-columns: repeat(6, 1fr) !important;
  }
}
#pen-size-span {
  font-family: rubik;
}
#toolbar {
  overflow-x: auto;
}
#toolbar::-webkit-scrollbar {
  height: 5px;
  border: 10px transparent solid;
}
#toolbar::-webkit-scrollbar * {
  background: transparent; 
}
#toolbar::-webkit-scrollbar-thumb {
  background:rgba(0,0,0,0.1) !important;
  width: 3px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  height: 6px;
}

.special > .panel > .button {
  cursor: pointer;
}
#properties-panel {
  overflow: auto !important;
}

#trash-icon {
  width: 
}


.territory-name.editing {
  outline: none;
  border: none;
}
#image-fill-picker-popup {
  display: grid;
  grid-template-columns: repeat(8, minmax(0, 1fr));
  column-gap: 10px;
}
#image-fill-picker-popup > * {
  border-radius: 10px;
  aspect-ratio: 1 / 1;
}
#image-fill-picker-popup > .add {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.08);
}
#image-fill-picker-popup > .add > .icon {
  opacity: 1;
  color: #939393;
  height: 30px;
  width: 30px;
}
#image-fill-picker-popup > .asset {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  padding-bottom: 3px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.03)
}
#image-fill-picker-popup > .asset.selected {
  outline: #2894d2 2px solid;
}
#image-fill-picker-popup > .asset > img {
  width: 100%;
  height: 60%;
  object-fit: cover;
}
#image-fill-picker-popup > .asset > p {
  margin: 0px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: rubik;
}


@media only screen and (max-width: 500px) {
  #intro-left-big {
    font-size: 30px;
  }
  #image-fill-picker-popup {
    grid-template-columns: repeat(4, 1fr) !important;
  }
}