@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');

@font-face {
  font-family: oblivian;
  src: url(../public/fonts/oblivian/normal/100.otf);
  font-weight: 100;
}
@font-face {
  font-family: oblivian;
  src: url(../public/fonts/oblivian/normal/200.otf);
  font-weight: 200;
}
@font-face {
  font-family: oblivian;
  src: url(../public/fonts/oblivian/normal/300.otf);
  font-weight: 300;
}
@font-face {
  font-family: oblivian;
  src: url(../public/fonts/oblivian/normal/400.otf);
  font-weight: 400;
}
@font-face {
  font-family: oblivian;
  src: url(../public/fonts/oblivian/normal/500.otf);
  font-weight: 500;
}
@font-face {
  font-family: oblivian;
  src: url(../public/fonts/oblivian/normal/600.otf);
  font-weight: 600;
}
@font-face {
  font-family: oblivian;
  src: url(../public/fonts/oblivian/normal/700.otf);
  font-weight: 700;
}
@font-face {
  font-family: oblivian;
  src: url(../public/fonts/oblivian/normal/800.otf);
  font-weight: 800;
}
@font-face {
  font-family: oblivian;
  src: url(../public/fonts/oblivian/normal/900.otf);
  font-weight: 900;
}
@font-face {
  font-family: oblivian;
  src: url(../public/fonts/oblivian/italic/100.otf);
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: oblivian;
  src: url(../public/fonts/oblivian/italic/200.otf);
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: oblivian;
  src: url(../public/fonts/oblivian/italic/300.otf);
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: oblivian;
  src: url(../public/fonts/oblivian/italic/400.otf);
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: oblivian;
  src: url(../public/fonts/oblivian/italic/500.otf);
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: oblivian;
  src: url(../public/fonts/oblivian/italic/600.otf);
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: oblivian;
  src: url(../public/fonts/oblivian/italic/700.otf);
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: oblivian;
  src: url(../public/fonts/oblivian/italic/800.otf);
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: oblivian;
  src: url(../public/fonts/oblivian/italic/900.otf);
  font-weight: 900;
  font-style: italic;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.saving.animating {
  animation: rotating 1.4s linear infinite;
}

.content-row {
  width: 1200px;
  max-width: calc(100% - 100px);
}
@media only screen and (max-width: 1150px) {
  .content-row {
    width: 1100px;
  }
}
@media only screen and (max-width: 1600px) and (min-width: 1300px) {
  .content-row {
    width: 1300px;
  }
}
@media only screen and (max-width: 1420px) and (min-width: 1300px) {
  .content-row {
    width: 1200px;
  }
}

@keyframes ui-hidden {
  0% {
    opacity: 1;
    pointer-events: none;
  }
  100% {
    opacity: 0;
    pointer-events: none;
  }
}
@keyframes ui-shown {
  0% {
    opacity: 0;
    pointer-events: auto;
  }
  100% {
    opacity: 1;
    pointer-events: auto;
  }
}

.ui-hidden {
  animation: ui-hidden 0.5s;
  animation-fill-mode: forwards;
}
.ui-shown {

}

