#background {
  width: 100%;
  height: 100%;
  background-color: #EFF0FD;
  display: flex;
}
/* #content {
  width: 1000px;
  height: 500px;
  display: flex;
}
#content > .right {
  width: 300px;
  background-color: #2A2E4A;
  border-radius: 10px;
  height: 100%;
  margin-left: 20px;
  position: relative;
  overflow: hidden;
  padding: 10px;
  box-sizing: border-box;
}
#content > .right > h2 {
  font-family: rubik;
  margin: 0px;
  margin-left: 8px;
  margin-bottom: 5px;
  color: white;
  opacity: 0.8;
  font-weight: 400;
  font-size: 20px;
}
#content > .right > #logo {
  position: absolute;
  width: 400px;
  bottom: -80px;
  left: 50%;
  transform: translate(-50%, 50%);
  display: none;
}
#content > .left {
  flex-grow: 1;
}
#login-buttons {
  display: flex;
  flex-direction: column;
}
#login-buttons > button {
  font-family: rubik;
  transition: opacity 0.1s;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  outline: none;
  cursor: pointer;
  border: none;
  margin-bottom: 10px;
}
#login-buttons > button > img {
  height: 80%;
}
#login-buttons > button > span {
  font-family: rubik;
  font-size: 20px;
  margin-left: 10px;
}
#login-buttons > button:hover {
  opacity: 0.9;
} */

#background > .left {
  flex-grow: 1;
}

#background > .right {
  width: 30%;
  background-color: #2A2E4A;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#background > .right > h1 {
  font-family: rubik;
  font-size: 40px;
  color: white;
  opacity: 1;
  font-weight: 400;
  margin-bottom: 2px;
}
#background > .right > p {
  font-family: rubik;
  color: white;
  opacity: 0.8;
  margin: 0px;
  font-size: 20px;
}
#background > .right > a {
  background: linear-gradient(90deg, #00e5d2, #0085d2);
  outline: none;
  border: none;
  cursor: pointer;
  transition: background-size 0.2s;
  -webkit-transition: background-size 0.2s;
  -moz-transition: background-size 0.2s;
  -ms-transition: background-size 0.2s;
  -o-transition: background-size 0.2s;
  background-size: 100% 100%;
  align-items: center;
  justify-content: center;
  font-family: rubik;
  color: white;
  font-size: 25px;
  padding: 10px;
  padding-inline: 40px;
  margin-top: 10px;
  border-radius: 5px;
  text-decoration: none;
}
#background > .right > a:hover {
  background-size: 200% 100%;
}
#background > .left {
  display: flex;
  align-items: center;
  justify-content: center;
}
#background > .left > .content {
  width: 400px;
}
#background > .left > .content > .title {
  font-family: rubik;
  font-size: 40px;
  opacity: 0.8;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0px;
}
#login-with {
  display: flex;
  height: 40px;
  width: 100%;
  margin-top: 15px;
  justify-content: center;
}
#login-with > img {
  padding: 5px;
  box-sizing: border-box;
  border-radius: 50%;
  outline: 2px #2A2E4A solid;
  margin-right: 15px;
  cursor: pointer;
}
#login-with > img:last-child {
  margin-right: 0px;
}

#background.mobile {
  display: none;
}
@media (max-width: 480px) {
  #background.mobile {
    display: flex;
  }
  #background.normal {
    display: none;
  }
}
@media (max-width: 380px) {
  #background.mobile > .bottom > .panel > .subtitle {
    font-size: 16px;
  }
}
#background.mobile {
  flex-direction: column;
  height: 100%;
}
#background.mobile > .top {
  background-color: #2A2E4A;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: url(../public/assets/seamless-pattern-1.svg);
  background-size: 300px;
  background-position: 15px 15px;
}
#background.mobile > .top > img {
  margin-bottom: 50px;
  user-select: none;
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.4));
  width: 65%;
}
#background.mobile > .bottom {
  flex-grow: 1;
  margin-top: -80px;
  background-color: #ECF0F2;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#background.mobile > .bottom > .panel {
  flex-grow: 1;
  background-color: white;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 15px;
  width: calc(100% - 60px);
  position: relative;
  z-index: 1;
  padding-inline: 20px;
  box-sizing: border-box;
}
#background.mobile > .bottom > .panel > .title {
  color: black;
  font-family: rubik;
  text-align: center;
  margin-top: 22px;
  font-size: 30px;
}
#background.mobile > .bottom > .panel > .subtitle {
  text-align: center;
  font-family: rubik;
  color: black;
  opacity: 0.6;
  font-size: 17px;
}
#background.mobile > .bottom > a {
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-family: rubik;
  color: #000000;
  opacity: 0.8;
  font-size: 25px;
}